<template>
    <div id="routePage">
        <l-map ref="routePageMap" :maxBounds="maxBounds" :zoom="zoom" :center="center" :options="option">
            <!--<l-tile-layer ref="mapLayer" :url="mapUrl" :options="mapSubdomains" />-->
            <l-tile-layer ref="mapLayer" :url="chinaOneUrl" :options="{tms:true}" />
        </l-map>

    </div>

</template>

<script>

    import L from 'leaflet'
    import { LMap, LTileLayer } from 'vue2-leaflet';
    //引入标记点 拍摄点
    import Vue from 'vue';
    import RouteMark from './RouteMark';
    const RouteMarkExtend = Vue.extend(RouteMark);

    export default {
        name: "RoutePage",

        components: {LMap, LTileLayer},
        data(){
            return{
                zoom: 12,
                center: L.latLng(36.2604541134187, 117.081861669118),
                maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
                option: {zoomControl: false, attributionControl: false, renderer: L.canvas()},
                // mapUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                chinaOneUrl:this.globalJS.china1mUrl_2021,
                mapSubdomains:{subdomains: ['0', '1', '2', '3']},

                routeId:"",
                markerObjectList:[],   //拍摄点创建的L.marker对象及markerId
                // currMarkerId:"",   //当前展示的拍摄点id

            }
        },

        methods:{

            //获取路线详情
            getRouteDetail(){
                this.$http.get("/earthApi/bestRoutePlan/getRoutePlan?mainInfoId=" + this.routeId).then(response => {
                    console.log(response.data)

                    if(response.data.status === 200){

                        //设置底图展示限制
                        this.$refs.routePageMap.mapObject.setMaxBounds(L.geoJSON(JSON.parse(response.body.data.bestRoutePlan.maxgeo)).getBounds())
                        this.$refs.routePageMap.mapObject.setMinZoom(response.body.data.bestRoutePlan.maxZoom + 1)


                        //绘制线
                        let routeColor = response.body.data.bestRoutePlan.routeColor;
                        let routeJsonLine = JSON.parse(JSON.parse(response.body.data.bestRoutePlan.routePlan).jsonLine)
                        for(let i=0;i<routeJsonLine.features.length;i++){
                            let latlngs = [
                                [routeJsonLine.features[i].geometry.coordinates[0][1],routeJsonLine.features[i].geometry.coordinates[0][0]],
                                [routeJsonLine.features[i].geometry.coordinates[1][1],routeJsonLine.features[i].geometry.coordinates[1][0]]
                            ];
                            L.polyline(latlngs,{color:'#'+routeColor.substr(2).slice(2) + routeColor.substr(2).slice(0,2),weight:5}).addTo(this.$refs.routePageMap.mapObject);
                        }

                        //标记点
                        let routeMarkerList = response.body.data.properties;
                        for(let i=0;i<routeMarkerList.length;i++){
                            let markerInfo = routeMarkerList[i];
                            let _this = this;
                            //绘制 标记点、拍摄点
                            let icon = L.divIcon({
                                className: 'custom-div-icon',
                                html: new RouteMarkExtend({
                                    propsData: {
                                        markerInfo: markerInfo,
                                    },
                                    methods: {
                                        //点击拍摄点事件
                                        clickRouteMarkerPoint() {
                                            console.log("!1111111")
                                            _this.clickRouteMarkerPointFun(markerInfo)
                                        },
                                    },
                                }).$mount().$el,
                                iconSize: markerInfo.properties.markType === '标记点' ? [11,11] : [28, 28],
                                iconAnchor: markerInfo.properties.markType === '标记点' ? [5.5,5.5] : [15, 9]
                            });
                            let marker = L.marker(
                                [JSON.parse(markerInfo.geometry).coordinates[1],JSON.parse(markerInfo.geometry).coordinates[0]],
                                {icon:icon}
                            ).addTo(this.$refs.routePageMap.mapObject);
                            if(markerInfo.properties.markType === '拍摄点'){
                                //存入拍摄点对象列表
                                this.markerObjectList.push({
                                    markerObject:marker,
                                    markerId:markerInfo.properties.id,
                                    lon:markerInfo.properties.lon,
                                    lat:markerInfo.properties.lat
                                });
                                //拍摄点气泡
                                marker.bindPopup(
                                    '<div class="routeMarkerPoint-bubble" onclick="intoRouteMarkerPointDetail(\''+ markerInfo.properties.id +'\')"><img src="' + markerInfo.properties.markThumb + '"/></div>',
                                    { className: 'routeMarkerPointPopup',closeButton:false}
                                )
                            }
                        }

                        //传拍摄点需要展示的信息给flutter
                        let markInfoList = [];
                        for(let i=0;i<response.body.data.properties.length;i++){
                            if(response.body.data.properties[i].properties.markType === "拍摄点"){
                                let currInfo = {
                                    markId:response.body.data.properties[i].properties.id,
                                    markThumb:response.body.data.properties[i].properties.markThumb,
                                    markName:response.body.data.properties[i].properties.markName,
                                    poi:response.body.data.properties[i].properties.poi,
                                    describe:response.body.data.properties[i].properties.describe,
                                }
                                markInfoList.push(currInfo)
                            }
                        }

                        //显示第一个拍摄点的气泡
                        if(this.markerObjectList.length !== 0){
                            this.markerObjectList[0].markerObject.openPopup();
                            //地图飞到第一个拍摄点位置
                            this.$refs.routePageMap.mapObject.flyTo(
                                L.latLng(this.markerObjectList[0].lat, this.markerObjectList[0].lon),response.body.data.bestRoutePlan.zoom,
                                {duration:2}
                            )
                        }
                        else{
                            //地图飞到地图中心点位置
                            this.$refs.routePageMap.mapObject.flyTo(
                                L.latLng(response.body.data.bestRoutePlan.lat, response.body.data.bestRoutePlan.lon),response.body.data.bestRoutePlan.zoom,
                                {duration:2}
                            )
                        }

                        //传相关信息给flutter
                        let flutterParam = {
                            routeTitle:response.body.data.bestRoutePlan.routePlanTitle,
                            markInfoList:JSON.stringify(markInfoList)
                        }
                        if(window["flutter_getMarkInfoList"]){
                            window["flutter_getMarkInfoList"].postMessage(JSON.stringify(flutterParam));
                        }
                    }
                    else if(response.data.status === 305 || response.data.status === 404){
                      this.$router.push(
                          {
                            path: '/NotExist',
                          }
                      )
                    }
                    else{
                        console.log(response.data.status + response.data.message)
                    }


                })
            },

            //被flutter调用，更改当前点击的marker
            changeCurrMarkerId(param){
                // this.currMarkerId = param;
                for(let i=0;i<this.markerObjectList.length;i++){
                    if(this.markerObjectList[i].markerId === param){
                        this.markerObjectList[i].markerObject.openPopup();
                        //地图飞到位置
                        this.$refs.routePageMap.mapObject.flyTo(
                            L.latLng(this.markerObjectList[i].lat, this.markerObjectList[i].lon),this.$refs.routePageMap.mapObject.getZoom(),
                        )
                        break;
                    }
                }

            },

            //mapbox中点击拍摄点时调用，更改当前marker，flutter展示相应弹窗
            clickRouteMarkerPointFun(markerInfo){
                // this.currMarkerId = markerInfo.properties.markerId;
                //地图飞到位置
                this.$refs.routePageMap.mapObject.flyTo(
                    L.latLng(markerInfo.properties.lat, markerInfo.properties.lon),this.$refs.routePageMap.mapObject.getZoom(),
                )
                if(window["flutter_clickRouteMarkerPoint"]){
                    window["flutter_clickRouteMarkerPoint"].postMessage(JSON.stringify(markerInfo));
                }
            },

            //点击气泡进入拍摄点详情
            intoRouteMarkerPointDetail(markId){
                if(window["flutter_intoRouteMarkerPointDetail"]){
                    window["flutter_intoRouteMarkerPointDetail"].postMessage(markId);
                }
            }

        },
        mounted(){
            window.leaflet = this;
            this.routeId = this.$route.query.routeId;
            this.getRouteDetail();
            window.intoRouteMarkerPointDetail = this.intoRouteMarkerPointDetail //将vue方法挂载到window对象
        }

    }





</script>

<style scoped>
    #routePage {
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*text-align: center;*/
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    }



</style>

<style>
    /*拍摄点气泡 popup样式修改*/
    .routeMarkerPointPopup{
        width:66px;
        height:64px;
    }
    .routeMarkerPointPopup .leaflet-popup-content{
       margin:0;
    }
    .routeMarkerPointPopup .leaflet-popup-content-wrapper{
        background:rgba(0,0,0,0);
        box-shadow:none;
    }
    /*拍摄点 气泡*/
    .routeMarkerPoint-bubble{
        position:absolute;
        /*top:-70px;*/
        /*left:-19px;*/
        width: 60px;
        height: 60px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.25);
        background-color: white;
        z-index:1;
    }
    .routeMarkerPoint-bubble:after{
        content:'';
        position:absolute;
        bottom:-16px;
        left:calc(50% - 12px);
        width:0;
        height:0;
        display:block;
        border-style:solid;
        border-width:8px 12px;
        border-color:#ffffff transparent transparent transparent;
        z-index: -1;
    }
    .routeMarkerPoint-bubble img{
        position:absolute;
        top:0;
        left:0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit:cover;
    }
</style>
