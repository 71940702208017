import Vue from 'vue'
import VueRouter from 'vue-router'
import ShootPlan from "@/views/ShootPlan";
import CrowdShoot from "@/views/CrowdShoot/CrowdShoot";
import CrowdShootDetail from "@/views/CrowdShoot/CrowdShootDetail";
import Compare from "@/views/CrowdShoot/Compare";
import Viewer from "@/views/CrowdShoot/Viewer";
import ArticleSlidingUpPanel from "@/views/Article/ArticleSlidingUpPanel";
import CompareHorizontal from "@/views/Article/CompareHorizontal";
import RoutePage from "@/views/PhotoLocation/RoutePage";
import RouteSharePage from "@/views/PhotoLocation/RouteSharePage";
import MarkDetailPage  from "@/views/PhotoLocation/MarkDetailPage";
import PanoramaSharePage from "@/views/Panorama/PanoramaSharePage";
import AlumniHeatMap from "../views/AlumniHeatMap/AlumniHeatMap";
import AlumniArticle from "../views/AlumniHeatMap/AlumniArticle";
import CrowdShooting from "@/views/CrowdShooting/CrowdShooting"
import Yijing from "@/views/Yijing/Yijing";
import PlaneRecognition from "../views/PlaneRecognition/PlaneRecognition";
import BubbleNews from "../views/BubbleNews/BubbleNews";
import Drones from "../views/Drones/Drones";
import RollerNews from "../views/RollerNews/RollerNews";
import FireAlarm from "../views/FireAlarm/FireAlarm";
import MyWish from "../views/OrbitSatellite/ActivityPage/ActivityPageFour/MyWish";
import SmartLayer from "../views/SmartLayer/SmartLayer"
import MarkHelpPage from '../views/MarkFileDetail/MarkHelpPage'
import MarkFileSave from '../views/MarkFileDetail/MarkFileSave'
import MarkFileJoin from  '../views/MarkFileDetail/MarkFileJoin'
import MarkFilePlay from '../views/MarkFileDetail/MarkFilePlay'
// import Display3D from "@/views/Display3D";
import ImageViewer from "@/views/ImageViewer";
import Event from "@/views/Event/Event";
import productMain from "../views/QuickFlash/productMain";
import LayerCompare from "@/views/LayerCompare/LayerCompare";
import OrbitSatellite from "../views/OrbitSatellite/OrbitSatellite"
import WallpaperSharePage from "@/views/Wallpaper/WallpaperSharePage";
import VideoSharePage from "@/views/Video/VideoSharePage";
import ToolboxSharePage from "@/views/Toolbox/ToolboxSharePage";
import TipsPage from "@/views/Tips/TipsPage";
import ArticleSharePage from "@/views/Article/ArticleSharePage";
import ArticlePage from "@/views/Article/ArticlePage";
import ShootPlanPage from "@/views/ShootPlan/ShootPlanPage";
import miniMap from "../views/QuickFlash/miniMap";
import collectionDetail from "../views/QuickFlash/collectionDetail";
import Agreement from "../views/PrivacyAndAgreement/Agreement";
import Privacy from "../views/PrivacyAndAgreement/Privacy";
import App from "@/views/App";
import AboutUsPage from "@/views/AppPage/AboutUsPage";
import ProductPageApp from "@/views/AppPage/ProductPageApp";
import ProductPageImageLib from "@/views/AppPage/ProductPageImageLib";
import ProductPageWebMarker from "@/views/AppPage/ProductPageWebMarker";
import PrivacyPolicyPage from "@/views/AppPage/PrivacyPolicyPage";
import TermsOfUsePage from "@/views/AppPage/TermsOfUsePage";

import AppHelpPage from '../views/AppHelpPage'
import OpenApp from "@/views/OpenApp";
import NotExist from "@/views/NotExist";
import Nameing from "../views/OrbitSatellite/Nameing";
import SatelliteActivityPageOne from "@/views/OrbitSatellite/ActivityPage/ActivityPageOne";
import HistoricalImages from "@/views/HistoricalImages/HistoricalImages";
import SatelliteActivityPageTwo from "@/views/OrbitSatellite/ActivityPage/ActivityPageTwo";
import SatelliteActivityPageThree from "@/views/OrbitSatellite/ActivityPage/ActivityPageThree";
import SatelliteActivityPageFour from "@/views/OrbitSatellite/ActivityPage/ActivityPageFour/ActicityPageFour";

import TurntableGame from "@/views/AppActivity/TurntableGame";
import OrderImage from "@/views/OrderImage/OrderImage";
import SpecialTopicMap from "@/views/SpecialTopicMap/SpecialTopicMap";
import GenerateRedeemCode from "@/views/GenerateRedeemCode/GenerateRedeemCode";
import CampusImage from "@/views/CampusImage/CampusImage";
import CampusActivity from "@/views/CampusImage/CampusActivity";
import ChoosePositionGlobe from "@/views/ChoosePositionGlobe/ChoosePositionGlobe";
import GraduationPhoto from "@/views/Event/H5Page/GraduationPhoto"
import MemberActivation from "@/views/MemberActivation"
import FootprintWorld from "@/views/FootprintWorld/FootprintWorld.vue";
import EditWishContent from "@/views/OrbitSatellite/ActivityPage/ActivityPageFour/CreateWish/EditWishContent";
import ApplyForWish from "@/views/OrbitSatellite/ActivityPage/ActivityPageFour/CreateWish/ApplyForWish";
import AreaCode from "@/views/Login/AreaCode";
import Login from "@/views/Login/Login";
import UserAuthInfo from "@/views/FootprintWorld/UserAuthInfo.vue";
import FootprintDetail from "@/views/FootprintWorld/FootprintDetail.vue";
import Cesium3dTilesHome from "@/views/Cesium3dTiles/Cesium3dTilesHome";
import Cesium3dTiles from "@/views/Cesium3dTiles/Cesium3dTiles";
import MarkLayer from "@/views/MarkLayer/MarkLayer";


Vue.use(VueRouter)

const routes = [
  {
    path: '/ShootPlan',
    name: 'ShootPlan',
    component: ShootPlan
  },
  {
    path: '/CrowdShoot/Home',
    name: 'CrowdShoot',
    component: CrowdShoot
  },
  {
    path: '/CrowdShoot/Detail',
    name: 'CrowdShootDetail',
    component: CrowdShootDetail
  },
  {
    path: '/CrowdShoot/Compare',
    name: 'Compare',
    component: Compare
  },
  {
    path: '/CrowdShoot/Viewer',
    name: 'Viewer',
    component: Viewer
  },
  {
    path: '/Article/ArticleSlidingUpPanel',
    name: 'ArticleSlidingUpPanel',
    component: ArticleSlidingUpPanel
  },
  {
    path: '/Article/CompareHorizontal',
    name: 'CompareHorizontal',
    component: CompareHorizontal
  },
  {
    path: '/Article/ArticleSharePage',
    name: 'ArticleSharePage',
    component: ArticleSharePage
  },
  {
    path: '/Article/ArticlePage',
    name: 'ArticlePage',
    component: ArticlePage
  },

  {
    path: '/PhotoLocation/RouteSharePage',
    name: 'RouteSharePage',
    component: RouteSharePage
  },
  {
    path: '/PhotoLocation/MarkDetailPage',
    name: 'MarkDetailPage',
    component: MarkDetailPage
  },
    {
        path: '/PhotoLocation/RoutePage',
        name: 'RoutePage',
        component: RoutePage
    },
    {
        path:'/Panorama/PanoramaSharePage',
        name:'PanoramaSharePage',
        component:PanoramaSharePage
    },
    {
        path:'/WallpaperSharePage',
        name:'WallpaperSharePage',
        component:WallpaperSharePage
    },
  {
    path:'/VideoSharePage',
    name:'VideoSharePage',
    component:VideoSharePage
  },
    {
        path:'/ToolboxSharePage',
        name:'ToolboxSharePage',
        component:ToolboxSharePage
    },
    {
        path:'/Tips/TipsPage',
        name:'TipsPage',
        component:TipsPage
    },
    {
        path:'/ShootPlanPage',
        name:'ShootPlanPage',
        component:ShootPlanPage
    },


  {
    path: '/AlumniHeatMap/AlumniHeatMap',
    name: 'AlumniHeatMap',
    component: AlumniHeatMap,
    meta:{
      title:"校友会"
    }
  },
  {
    path: '/AlumniHeatMap/AlumniArticle',
    name: 'AlumniArticle',
    component: AlumniArticle,
    meta:{
      title:"校友会"
    }
  },
  {
    path: '/FireAlarm',
    name: 'FireAlarm',
    component: FireAlarm,
    meta:{
      title:"森林火灾风险预警",
    }
  },
  {
    path: '/Yijing',
    name: 'Yijing',
    component: Yijing,
    meta:{
      title:"城市洞察",
    }
  },
  {
    path: '/PlaneRecognition',
    name: 'PlaneRecognition',
    component: PlaneRecognition,
    meta:{
      title:"飞机识别",
    }
  },
  {
    path: '/Drones',
    name: 'Drones',
    component: Drones
  },
  {
    path: '/RollerNews',
    name: 'RollerNews',
    component: RollerNews
  },
  {
    path: '/BubbleNews',
    name: 'BubbleNews',
    component: BubbleNews,
  },
    {
        path:'/CrowdShooting',
        name:'CrowdShooting',
        component: CrowdShooting,
        meta:{
            title:"众筹拍摄"
        }
    },
    {
        path: '/OrbitSatellite',
        name: 'OrbitSatellite',
        component: OrbitSatellite,
    },
  {
    path: '/OrbitSatellite/Nameing',
    name: 'Nameing',
    component: Nameing,
  },
  {
    path: '/Display3D',
    name: 'Display3D',
    component: resolve => require(['../views/Display3D/Display3D'], resolve),
    meta:{
      title:"3D展示",
    }
  },
  {
    path: '/ImageViewer',
    name: 'ImageViewer',
    component: ImageViewer,
    meta:{
      title:"吉林一号卫星影像"
    }
  },
  {
    path: '/Event',
    name: 'Event',
    component: Event,
    meta:{
      title:"共生地球APP活动"
    }
  },
    {
        path: '/LayerCompare',
        name: 'LayerCompare',
        component: LayerCompare,
        // meta:{
        //     title:"卷帘对比"
        // }
    },
  {
    path: '/QuickFlash/productMain',
    name: 'QuickFlash',
    component: productMain,
    meta:{
      title:"卫星快闪"
    }
  },
  {
    path: '/QuickFlash/miniMap',
    name: 'miniMap',
    component: miniMap,
    meta:{
    }
  },
  {
    path: '/QuickFlash/collectionDetail',
    name: 'collectionDetail',
    component: collectionDetail,
    meta:{
      title:"卫星快闪"
    }
  },  {
    path: '/PrivacyAndAgreement/agreement',
    name: 'agreement',
    component: Agreement,
    meta:{
      title:"共生地球"
    }
  },
  {
    path: '/PrivacyAndAgreement/privacy',
    name: 'privacy',
    component: Privacy,
    meta:{
      title:"共生地球"
    }
  },
  {
    path: '/',
    name: 'app',
    component: App,
    meta:{
      title:"共生地球"
    }
  },
  {
    path: '/AboutUs',
    name: 'AboutUsPage',
    component: AboutUsPage,
    meta:{
      title:"关于我们"
    }
  },
  {
    path: '/ProductPageApp',
    name: 'ProductPageApp',
    component: ProductPageApp,
    meta:{
      title:"共生地球APP"
    }
  },
  {
    path: '/ProductPageWebMarker',
    name: 'ProductPageWebMarker',
    component: ProductPageWebMarker,
    meta:{
      title:"共生地球web端"
    }
  },
  {
    path: '/ProductPageImageLib',
    name: 'ProductPageImageLib',
    component: ProductPageImageLib,
    meta:{
      title:"遥感影像库"
    }
  },
  {
    path: '/PrivacyPolicyPage',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage,
    meta:{
      title:"隐私协议"
    }
  },
  {
    path: '/TermsOfUsePage',
    name: 'TermsOfUsePage',
    component: TermsOfUsePage,
    meta:{
      title:"使用条款"
    }
  },




  {
    path: '/OpenApp',
    name: 'openApp',
    component: OpenApp,
    meta:{
      title:"下载共生地球App"
    }
  },
  {
    path:'/NotExist',
    name:'NotExist',
    component:NotExist,
    meta:{
      title:"共生地球App"
    }
  },
  {
    path:'/SatelliteActivityPageOne',
    name:'SatelliteActivityPageOne',
    component:SatelliteActivityPageOne,
  },
  {
    path:'/SatelliteActivityPageTwo',
    name:'SatelliteActivityPageTwo',
    component:SatelliteActivityPageTwo,
  },
  {
    path:'/SatelliteActivityPageThree',
    name:'SatelliteActivityPageThree',
    component:SatelliteActivityPageThree,
  },
  {
    path:'/SatelliteActivityPageFour',
    name:'SatelliteActivityPageFour',
    component:SatelliteActivityPageFour,
  },

  {
    path:'/HistoricalImages',
    name:'HistoricalImages',
    component:HistoricalImages,
  },
  {
    path:'/CampusActivity',
    name:'CampusActivity',
    component:CampusActivity,
    meta: {
      title: "校园活动"
    }
  },
  {
    path:'/CampusImage',
    name:'CampusImage',
    component:CampusImage,
  },
  {
    path:'/AppActivity/TurntableGame',
    name:'TurntableGame',
    component:TurntableGame,
  },
  {
    path:'/OrderImage',
    name:'OrderImage',
    component:OrderImage,
  },
  {
    path:'/SpecialTopicMap',
    name:'SpecialTopicMap',
    component:SpecialTopicMap,
  },
  {
    path:'/GenerateRedeemCode',
    name:'GenerateRedeemCode',
    component:GenerateRedeemCode,
    meta: {
      title: "专属图层兑换码"
    }
  },
  {
    path:'/ChoosePositionGlobe',
    name:'ChoosePositionGlobe',
    component:ChoosePositionGlobe,
  },
  {
    path:'/ApplyForWish',
    name:'ApplyForWish',
    component:ApplyForWish,
  },
  {
    path: '/Event/GraduationPhoto',
    name: 'GraduationPhoto',
    component: GraduationPhoto,
    meta:{
      title:"共生地球太空毕业照"
    }
  },
  {
    path: '/EditWishContent',
    name: 'EditWishContent',
    component: EditWishContent,
    meta:{
      title:"共生地球太空毕业照"
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta:{
      title:"登录-吉林一号·共生地球"
    }
  },
  {
    path: '/AreaCode',
    name: 'AreaCode',
    component: AreaCode,
    meta:{
      title:"登录-吉林一号·共生地球"
    }
  },
  {
    path: '/MyWish',
    name:'MyWish',
    component: MyWish,
  },
  {
    path: '/MemberActivation',
    name: 'MemberActivation',
    component: MemberActivation,
    meta:{
      title:"激活共生地球会员"
    }
  },
  {
    path: '/FootPrintWorld',
    name: 'FootPrintWorld',
    component: FootprintWorld,
    meta:{
      title:"足迹世界"
    }
  },
  {
    path: '/UserAuthInfo',
    name: 'UserAuthInfo',
    component: UserAuthInfo,
    meta:{
      title:"信息授权协议"
    }
  },
  {
    path: '/FootprintDetail',
    name: 'FootprintDetail',
    component: FootprintDetail,
    meta:{
      title:"足迹世界"
    }
  },
  {
    path: '/SmartLayer',
    name:'SmartLayer',
    component: SmartLayer
  },
  {
    path: '/MarkHelpPage',
    name:'MarkHelpPage',
    component: MarkHelpPage
  },
  {
    path: '/AppHelpPage',
    name:'AppHelpPage',
    component: AppHelpPage
  },
  {
    path:'/MarkFileSave',
    name:'MarkFileSave',
    component: MarkFileSave,
    meta:{
      title:"标记分享"
    }
  },
  {
    path: '/MarkFileJoin',
    name:'MarkFileJoin',
    component: MarkFileJoin,
    meta:{
      title:"标记分享"
    }
  },
  {
    path: '/MarkFilePlay',
    name:'MarkFilePlay',
    component: MarkFilePlay,
  },
  {
    path: '/Cesium3dTilesHome',
    name:'Cesium3dTilesHome',
    component: Cesium3dTilesHome,
  },
  {
    path: '/Cesium3dTiles',
    name:'Cesium3dTiles',
    component: Cesium3dTiles,
  },
  {
    path: '/MarkLayer',
    name:'MarkLayer',
    component: MarkLayer,
  }






]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: 'appTest',
  routes
})

export default router
