<template>
    <div class="smartLayer">
        <div id="layerMap" style="width: 100%;height: 100%;background: black;position: absolute;top: 0;left: 0; overflow: hidden;">
            <heat-map v-if="layerTypeSelect === 'heatmap'" ref="heatMap" :map="map" :dataPropertyName="'pValue'" :dataMin="legend.minValue" :dataMax="legend.maxValue" :colorList="legend.colorList"
                      :layerOpacity="opacity" :pointData="dataArray"></heat-map>
            <histogram-map v-if="layerTypeSelect === 'hismap'" ref="hisMap"  :map="map" :featureCollection="dataArray" :dataMin="legend.minValue" :dataMax="legend.maxValue" :colorList="legend.colorList" :layerOpacity="opacity"></histogram-map>
            <data-grid v-if="layerTypeSelect === 'dataGrid'" ref="dataGrid" :map="map" :dataPropertyName="'pValue'" :dataMin="legend.minValue" :dataMax="legend.maxValue" :colorList="legend.colorList" :layerOpacity="opacity"
                       :pointData="dataArray"></data-grid>
        </div>
        <!--按钮组-->
        <div v-if="configShow || share ==1" style="width: 255px;height: 40px;font-size: 12px;border-radius: 4px;background: rgba(255,255,255,0.4);position: absolute; left: 50%;top: 86px; transform: translateX(-50%);display: flex;align-items: center;justify-content: center">
            <div @click="selectLayerType('dataGrid')" style="width: 80px;height: 31px;border-radius: 4px;display: flex;align-items: center;justify-content: center;cursor: pointer" :style="{'background': layerTypeSelect ==='dataGrid'? 'rgba(42,130,228,1)':'','color':layerTypeSelect ==='dataGrid' ? 'white':'#4a4a4a'}">
                <div style="line-height: 16px">网格图</div>
                <img src="../../assets/SmartLayer/datagrid.png" style="margin-left: 5px;height: 16px;width: 16px;" />
            </div>

            <div @click="selectLayerType('hismap')" style="width: 80px;height: 31px;border-radius: 4px;display: flex;align-items: center;justify-content: center;cursor: pointer" :style="{'background': layerTypeSelect ==='hismap'? 'rgba(42,130,228,1)':'','color':layerTypeSelect ==='hismap' ? 'white':'#4a4a4a'}">
                <div style="line-height: 16px">柱状图</div>
                <img src="../../assets/SmartLayer/hismap.png"  style="margin-left: 5px;height: 16px;width: 16px" />
            </div>
            <div @click="selectLayerType('heatmap')" style="width: 80px;height: 31px;border-radius: 4px;display: flex;align-items: center;justify-content: center;cursor: pointer" :style="{'background': layerTypeSelect ==='heatmap'? 'rgba(42,130,228,1)':'','color':layerTypeSelect ==='heatmap' ? 'white':'#4a4a4a'}">
                <div style="line-height: 16px">热力图</div>
                <img src="../../assets/SmartLayer/heatmap.png"  style="margin-left: 5px;height: 16px;width: 16px" />
            </div>
        </div>
        <!--卡片信息-->
        <div v-if="infoCardShow" style="width: 300px;max-height: 330px; min-height: 91px;position: absolute; left: 50%;top: 139px; transform: translateX(-50%);border-radius: 5px">
            <div  style="width: 100%;background: white;border-radius: 5px;box-shadow: 0 4px 23px rgba(0,0,0,0.25);max-height: 330px;min-height: 91px;overflow-y: scroll;overflow-x: hidden;" class="smartLayerInfo">
                <div style="width: 100%;display: flex;flex-direction: column" class="bgImg">
<!--                    <div style="height: 100%;width: 100%;display: flex;justify-content: right">-->
<!--                        <img src="../../assets/SmartLayer/bg.png" style="height: 97px;width: 78px"/>-->
<!--                    </div>-->
                    <div style="width: 100%;display: flex;flex-direction: column;">
                        <div style="line-height: 22px;color: black;margin-left: 12px;margin-top: 19px;margin-right: 12px;text-align: justify">{{title}}</div>
                        <div style="line-height: 21px;color: rgba(151, 151, 151, 1);font-size: 14px;margin-left: 12px;margin-top: 4px">数据来源：{{source}}</div>
                        <div style="display: flex; justify-content: space-between;margin-top: 2px;margin-bottom: 5px;">
                            <div style="line-height: 21px;width: 56px;font-size: 14px;margin-left: 12px">更多信息</div>
                            <div v-if="showSimpleInfo"  @click="showSimpleInfo = false" class="iconfontFamily" style="color: black;font-size:16px;line-height: 21px;width: 16px;margin-right: 5px;cursor: pointer">&#xe788;</div>
                        </div>
                    </div>
                </div>

                <div v-if="!showSimpleInfo" style="width: 100%;font-size: 14px;">
                    <div style="padding: 0px 12px;text-align: justify">{{context}}</div>
                    <div style="width: 100%;margin-top: 4px">
                        <div style="line-height: 21px;margin-left: 12px;">图例说明</div>
                        <!--渐变-->
                        <div v-if="layerTypeSelect ==='heatmap'" style="margin: 12px 12px 50px">
                            <div style="width: 100%;height: 20px;" :style="{'background':barBackground}"></div>
                            <div style="width: 100%;margin-top: 5px;">
                                <div class="text-style" style="float: left">{{legend.minValue}}</div>
                                <div class="text-style" style="float: right">{{legend.maxValue}}</div>
                            </div>
                        </div>
                        <div v-else style="display: flex;margin: 12px 12px 22px;width: 276px;flex-wrap: wrap;">
                            <template  v-for="(item,index) in legend.colorList">
                                <div :key="index" style="width: 55px;margin-bottom: 8px">
                                    <div style="width: 55px;height: 20px" :style="{'background':item.hexColor}"></div>
                                    <div style="width: 55px;line-height: 15px;margin-top: 5px;text-align: center;font-size: 12px">{{item.text}}</div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div v-if="!showSimpleInfo" style="height: 21px;position: absolute;width: calc(100% - 4px);background: white;border-bottom-left-radius: 4px;left: 0;bottom: 0;display: flex;align-content: center;justify-content: space-between">
                        <div style="height: 21px;width: 20px"></div>
                        <div  @click="showSimpleInfo = true" class="iconfontFamily " style="float: right;color: black;font-size:16px;line-height: 21px;width: 16px;margin-right: 3px;cursor: pointer">&#xe78f;</div>
                    </div>
                </div>

            </div>

        </div>
        <!--底部操作栏-->
        <div v-if="configShow || share ==1" style="width: calc(100% - 48px);height: 30px;position: absolute;left: 50%;transform: translateX(-50%);display: flex;align-items: center" :style="{'bottom':share == 0 ? '121px':'22px'}">
            <div style="height:30px;width: calc(100% - 62px);">
                <el-slider class="opacitySlider" v-model="opacity" style="width: 100%;height: 30px" :show-tooltip="false" :min="0" :max="1" :step="0.01" @change="changeOpacity"></el-slider>
            </div>
            <div style="height: 30px;width: 30px;border-radius: 4px;background: rgba(42, 130, 228, 1);text-align: center;margin-left: 27px" @click="reSetFun">
                <div style="font-size: 14px;color: white;line-height: 30px;" class="iconfontFamily">&#xe74f;</div>
            </div>
        </div>

        <TopShareBar v-if="share ==1" />
    </div>
</template>

<script>
    import {getMapboxToken} from "@/utils/utils";
    import mapboxgl from "mapbox-gl";
    import TopShareBar from '@/components/TopShareBar';
    import HeatMap from './SmartLayerComponents/HeatMap'
    import HistogramMap from "./SmartLayerComponents/HistogramMap";
    import DataGrid from "./SmartLayerComponents/DataGrid";
    import * as turf from "@turf/turf";
    import {getWxSignature} from "@/utils/shareConfig";
    export default {
        name: "SmartLayer",
        components:{
            HeatMap,
            HistogramMap,
            DataGrid,
            TopShareBar,
        },
        data(){
            return{
                map:'',
                layerTypeSelect:'',//默认：网格图 dataGrid，柱状图 hismap ,热力图 heatmap
                share:0,//分享
                opacity:1,//图层透明度
                dataArray:[],//
                context:'',
                title:'',
                source:'',
                legend:{
                    minValue:0,
                    maxValue:0,
                    colorList:[
                        {
                            "hexColor": "#BE1111",
                            "color": "#BE1111FF",
                            "text": "优秀"
                        },
                    ],
                },
                showSimpleInfo:true,//是否展示缩略信息
                infoCardShow:true,
                configShow:false
            }
        },
        methods:{
            async initMapbox(){
                await getMapboxToken()
                mapboxgl.accessToken = this.globalJS.mapboxToken;
                this.map = new mapboxgl.Map({
                    container: 'layerMap',
                    center:[100, 31],
                    zoom: 3,
                    style: this.globalJS.mapboxStyle,
                    preserveDrawingBuffer:true,
                    localIdeographFontFamily: '',
                    projection: {name:'globe'},
                    transformRequest: (url) => {
                        if (url.indexOf('https://api.mapbox.com') > -1) {
                            return {
                                url: url.replace('https://api.mapbox.com', 'https://earth.jl1.cn/mapbox'),
                            };
                        }
                    }
                });
                this.map.on('load', () => {
                    this.map.addSource('mapbox-dem', {
                        type: 'raster-dem',
                        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
                        tileSize: 512,
                        maxZoom: 14,
                    });
                    this.map.setTerrain({'source': 'mapbox-dem', 'exaggeration': 1});
                    this.map.addLayer({
                        id: 'sky',
                        type: 'sky',
                        paint: {
                            'sky-type': 'atmosphere',
                            'sky-atmosphere-sun': [0.0, 0.0],
                            'sky-atmosphere-sun-intensity': 15,
                        }
                    });

                    this.map.addSource('china1m2021', {
                        type: 'raster',
                        tiles: [
                            this.globalJS.china1mUrl_2021
                        ],
                        tileSize: 256,
                        scheme: 'tms',
                    });
                    this.map.addLayer({
                        id: 'china1mLayer',
                        type: 'raster',
                        source: 'china1m2021',
                    });
                    if(this.$route.query.infoId)
                        this.getSmartCityById(this.$route.query.infoId)
                });
            },
            //选择图层
            selectLayerType(val){
                this.layerTypeSelect = val
            },
            getSmartCityById(id){
                this.$http.get('/earthApi/main-info/getSmartCityById?infoId='+id).then(response => {
                    if(response.body.status == 200){
                        this.dataArray =response.body.data.geoPoints
                        this.context = response.body.data.context
                        this.title = response.body.data.title
                        this.source = response.body.data.source
                        this.legend.maxValue = response.body.data.legend.maxValue
                        this.legend.minValue = response.body.data.legend.minValue
                        this.legend.colorList = response.body.data.legend.colorList
                        document.title = response.body.data.mainTitle
                        // setTimeout(()=>this.layerTypeSelect = 'heatmap',100)
                        // this.mapFitBoundary(response.body.data.geoPoints)
                        this.layerTypeSelect = response.body.data.layerType
                    }
                })
            },

            mapFitBoundary(geo){
                const bbox = turf.bbox(geo)
                this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]],{
                    linear: false,
                    animate: true,
                    // padding: {top: 120, bottom:130, left: 190, right: 500},
                })
            },
            showLayerCard(val){
                if(val === 1)
                    this.infoCardShow = false
                else
                    this.infoCardShow = true
            },
            showLayerConfig(val){
                if(val === 1)
                    this.configShow = false
                else
                    this.configShow = true
            },
            //改变透明度
            changeOpacity(){
                if(this.layerTypeSelect === 'heatmap'){
                    this.$refs.heatMap.reSetLayer(this.opacity)
                }
                if(this.layerTypeSelect === 'hismap'){
                    this.$refs.hisMap.reSetLayer(this.opacity)
                }
                if(this.layerTypeSelect === 'dataGrid')
                    this.$refs.dataGrid.reSetLayer(this.opacity)
            },
            reSetFun(){
                this.opacity = 1
                this.mapFitBoundary(this.dataArray)
                if(this.layerTypeSelect === 'heatmap'){
                    this.$refs.heatMap.reSetLayer(this.opacity)
                }
                if(this.layerTypeSelect === 'hismap'){
                    this.$refs.hisMap.reSetLayer(this.opacity)
                }
                if(this.layerTypeSelect === 'dataGrid')
                    this.$refs.dataGrid.reSetLayer(this.opacity)
            }
        },
        computed:{
            barBackground(){
                if(this.legend.colorList.length === 0){
                    return
                }else{
                    let colorCount = this.legend.colorList.length
                    let step = 1/(colorCount - 1)
                    let colorStr = 'linear-gradient(90deg, '
                    this.legend.colorList.forEach((item,index)=>{
                        colorStr = colorStr + item.hexColor + ' ' + step*index*100 + '%'
                        if(index !== colorCount - 1){
                            colorStr = colorStr + ','
                        }
                    })
                    colorStr = colorStr + ')'
                    return colorStr
                }
            },
        },
        mounted() {
            window.smartLayerInfo = this
            this.share = this.$route.query.share
            getWxSignature(this.$route.query.infoId,"标记文件")
            this.initMapbox()
        }
    }
</script>

<style scoped>

    . smartLayer {
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    /*//text-align: center;*/
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    /*// 禁止长按选择文字*/
    -webkit-user-select: none;

    /*// 禁止长按呼出菜单*/
    -webkit-touch-callout: none;
    }
    .text-style{
        font-size: 14px;
        color: rgba(74, 74, 74, 1);
    }

    .smartLayerInfo::-webkit-scrollbar {
        width: 0px; /*对垂直滚动条有效*/
        height: 0;  /*对水平滚动条有效*/
    }
    /*定义滚动条的轨道颜色、内阴影及圆角*/
    .smartLayerInfo::-webkit-scrollbar-track {
        background-color: #FFFFFF;
        /*border-radius: 2px;*/
        border-top-right-radius:  4px;
        border-bottom-right-radius: 4px;
        width: 0;
    }
    /*定义滑块颜色、内阴影及圆角*/
    .smartLayerInfo::-webkit-scrollbar-thumb {
        background-color: rgba(42, 130, 228, 1);
        border-radius: 2px;
    }
    .bgImg{
        background: url("../../assets/SmartLayer/bg.png") no-repeat right top;
    }




</style>
<style>
    .opacitySlider .el-slider__runway{
        margin: 13px 0 !important;
    }
    .opacitySlider .el-slider__button{
        border: none !important;
    }
    .opacitySlider .el-slider__bar{
        background: linear-gradient(90deg, rgba(48, 104, 255, 1) 0%, rgba(136, 159, 250, 1) 100%) !important;
    }
</style>
