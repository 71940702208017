<template>
    <div class="CrowdShooting">
        <l-map ref="map" :maxBounds="maxBounds" :zoom="zoom" :center="center" :options="option" :minZoom="minZoom">
             <l-tile-layer ref="mapLayer" :url="infoUrl" :options="{tms:true}" />
            <!-- <l-tile-layer :url="citeUrl" :options="{tms:true}" />-->
            <l-custom-marker
                    v-for = "item in crowdBubbleList"
                    :key="item.id"
                    :imgUrl="item.thumb"
                    :color1="'#ffffff'"
                    :color2="'rgba(0,0,0,0.5)'"
                    :latlon=[item.lat,item.lon]
                    :title="item.title"
                    :width="100"
                    :index="500"
                    @click="clickBubble(item.id)"
            />
            <l-custom-marker
                    v-for="item in crowdBubbleSelected"
                    :key="item.id+'bubble'"
                    :imgUrl="item.thumb"
                    :color1="'#ecc012'"
                    :color2="'#ecc012'"
                    :latlon=[item.lat,item.lon]
                    :title="item.title"
                    :width="100"
                    :index="1000"
                    @click="clickBubble(item.type)"
            />
        </l-map>
        <!--标题-->
      <!--  <div v-if="share == 1" class="titleBar">
            <p style="line-height:64px;">{{crowdTitle}}</p>
        </div>-->

        <!--底部信息栏-->
        <div v-if="share == 1" class="markInfoBar">
            <!--底部信息卡-->
            <div style="width:100%;height:105px;margin-top:21px;padding-left:32px;white-space: nowrap;">
                <div v-for="(item,index) in crowdBubbleList" :key="index" class="markInfoBar-item">
                    <img :src="item.thumb" class="markInfoBar-item-image">
                    <div style="width: calc(100% - 119px);margin-top:15px;float:left;">
                        <p class="markInfoBar-item-title">{{item.title}}</p>
                        <p class="markInfoBar-item-poi">
                            <span style="color:#675CB5;" class="iconfontFamily">&#xe61e;</span>
                            {{geoName}}
                        </p>
                        <p class="markInfoBar-item-describe">{{context}}</p>
                    </div>
                </div>
            </div>
            <!--指示条-->
            <div style="width: calc(100% - 198px);height:4px;margin:18px 99px 0 99px;display:flex;">
                <div v-for="(item,index) in crowdBubbleList" :key="'pagination'+index"
                     :style="{'background':index===0 ? '#7f7f7f' : '#d8d8d8'}"
                     style="height:100%;margin-left:2px;margin-right:2px;flex:1;"></div>
            </div>
        </div>
        <TopShareBar v-if="share ==1" />
    </div>
</template>

<script>
    import { LMap, LTileLayer} from 'vue2-leaflet';
    import TopShareBar from '@/components/TopShareBar';
   import LCustomMarker from "../../components/Vue2LeafletCustomMarker"
    import L from "leaflet";
    import {getWxSignature} from "@/utils/shareConfig";
    export default {
        name: "CrowdShooting",
        components:{
            LMap,
            LTileLayer,
            TopShareBar,
            LCustomMarker
            // LMarker,
        },
        data(){
            return{
                zoom: 12,
                minZoom:2,
                center: L.latLng(40.074, 116.604),
                maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
                option: {zoomControl: false, attributionControl: false, renderer: L.canvas()},
                mapUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                //infoUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                //infoUrl:this.globalJS.china1mUrl_2020,
                infoUrl:this.globalJS.china1mUrl_2021,
                mapSubdomains:{subdomains: ['0', '1', '2', '3']},
                infoId:'',
                share:0,
                crowdBubbleList:[],//气泡列表
                crowdBubbleSelected:[],//被选中的气泡
                crowdTitle:'',
                geoName:'',
                context:'',
            }
        },
        methods:{
            getCrowdBubbleList(){
                let list = {
                    context:'',
                    crowdFundingBubbleDtoList:[],
                    geoName:'',
                    infoId:'',
                    maxZoom:0,
                    maxgeo:'',
                    title:'',
                    zoom:12
                }
                this.crowdBubbleList = []
                this.crowdBubbleSelected = []
                this.$http.get('/earthApi/crowd-funding-shoot/getCrowdList?infoId='+this.infoId).then(response=>{
                    if(response.body.status == 200){
                        list = response.body.data
                        this.crowdBubbleList = list.crowdFundingBubbleDtoList
                        this.crowdTitle = list.title
                        this.geoName = list.geoName
                        this.context = list.context
                        if(this.crowdBubbleList.length !=0){
                            this.crowdBubbleSelected.push(this.crowdBubbleList[0])
                        }
                        //地图跳转
                        this.$refs.map.mapObject.setView( L.latLng(this.crowdBubbleList[0].lat,this.crowdBubbleList[0].lon),response.body.data.zoom)
                        this.maxBounds =  L.geoJSON(JSON.parse(list.maxgeo)).getBounds()
                        this.minZoom = list.maxZoom
                    }else{
                        this.$router.push(
                            {
                                path: '/NotExist',
                            }
                        )
                    }

                })
            },
            clickBubble(val){
                // eslint-disable-next-line no-undef
                OpenCrowdBubble.postMessage(val);
                this.crowdJumpTo(val);
            },

            crowdJumpTo(id){
                //this.selectBubble = bubbleId;
                this.crowdBubbleSelected = []
                this.crowdBubbleList.find((item)=>{
                    if(item.id ==id){
                        this.crowdBubbleSelected.push(item)
                        this.$refs.map.mapObject.flyTo(L.latLng(item.lat,item.lon),parseInt(this.$refs.map.mapObject.getZoom()))
                    }
                })

            }
        },
        mounted() {
            window.leaflet = this
            this.infoId = this.$route.query.infoId
            this.share = this.$route.query.share
            getWxSignature(this.infoId)
            this.getCrowdBubbleList()
        }
    }
</script>

<style lang="scss" scoped>
    .CrowdShooting {
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        //text-align: center;
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    }
    .CrowdShooting p{
        margin:0;
    }
    .titleBar {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:64px;
        background:#F4F4F4;
        color:#4a4a4a;
        font-size:16px;
        font-weight:bold;
        text-align:center;
        z-index: 999;
    }
    .markInfoBar{
        position:absolute;
        bottom:0;
        width:100%;
        height:180px;
        background:#ffffff;
        border-radius:12px 12px 0 0;
        z-index:999;
        overflow-x: hidden;
    }
    .markInfoBar-item{
        width:calc(100% - 64px);
        height:105px;
        border-radius:8px;
        box-shadow:0 0 6px 0 rgba(164,164,164,0.25);
        margin-right:15px;
        display:inline-block;
        white-space: normal;
    }
    .markInfoBar-item-image{
        width:100px;
        height:75px;
        border-radius:12px;
        margin:15px 5px 0 9px;
        float:left;
    }
    .markInfoBar-item-title{
        color:#4A4A4A;
        font-size:14px;
    }
    .markInfoBar-item-poi{
        margin-top:3px !important;
        color:#4A4A4A;
        font-size:10px;
    }
    .markInfoBar-item-describe{
        margin-top:3px !important;
        color:#4A4A4A;
        font-size:12px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
</style>
