<template>
    <div class="markLayer">
        <div id="layerMap" style="width: 100%;height: 100%;background: black;position: absolute;top: 0;left: 0; overflow: hidden;">
            <heat-map v-if="layerTypeSelect === 'heatmap'" ref="heatMap" :map="map" :dataPropertyName="'pValue'" :dataMin="legend.minValue" :dataMax="legend.maxValue" :colorList="legend.colorList"
                      :layerOpacity="opacity" :pointData="dataArray"></heat-map>
            <histogram-map v-if="layerTypeSelect === 'hismap'" ref="hisMap"  :map="map" :featureCollection="dataArray" :dataMin="legend.minValue" :dataMax="legend.maxValue" :colorList="legend.colorList" :layerOpacity="opacity"></histogram-map>
            <data-grid v-if="layerTypeSelect === 'dataGrid'" ref="dataGrid" :map="map" :dataPropertyName="'pValue'" :dataMin="legend.minValue" :dataMax="legend.maxValue" :colorList="legend.colorList" :layerOpacity="opacity"
                       :pointData="dataArray"></data-grid>
        </div>
        <!--按钮组-->
        <div v-if="configShow || share ==1" style="width: 255px;height: 40px;font-size: 12px;border-radius: 4px;background: rgba(255,255,255,0.4);position: absolute; left: 50%;top: 86px; transform: translateX(-50%);display: flex;align-items: center;justify-content: center">
            <div @click="selectLayerType('dataGrid')" style="width: 80px;height: 31px;border-radius: 4px;display: flex;align-items: center;justify-content: center;cursor: pointer" :style="{'background': layerTypeSelect ==='dataGrid'? 'rgba(42,130,228,1)':'','color':layerTypeSelect ==='dataGrid' ? 'white':'#4a4a4a'}">
                <div style="line-height: 16px">网格图</div>
                <img src="../../assets/SmartLayer/datagrid.png" style="margin-left: 5px;height: 16px;width: 16px;" />
            </div>

            <div @click="selectLayerType('hismap')" style="width: 80px;height: 31px;border-radius: 4px;display: flex;align-items: center;justify-content: center;cursor: pointer" :style="{'background': layerTypeSelect ==='hismap'? 'rgba(42,130,228,1)':'','color':layerTypeSelect ==='hismap' ? 'white':'#4a4a4a'}">
                <div style="line-height: 16px">柱状图</div>
                <img src="../../assets/SmartLayer/hismap.png"  style="margin-left: 5px;height: 16px;width: 16px" />
            </div>
            <div @click="selectLayerType('heatmap')" style="width: 80px;height: 31px;border-radius: 4px;display: flex;align-items: center;justify-content: center;cursor: pointer" :style="{'background': layerTypeSelect ==='heatmap'? 'rgba(42,130,228,1)':'','color':layerTypeSelect ==='heatmap' ? 'white':'#4a4a4a'}">
                <div style="line-height: 16px">热力图</div>
                <img src="../../assets/SmartLayer/heatmap.png"  style="margin-left: 5px;height: 16px;width: 16px" />
            </div>
        </div>

        <!--底部操作栏-->
        <div v-if="configShow || share ==1" style="width: calc(100% - 48px);height: 30px;position: absolute;left: 50%;transform: translateX(-50%);display: flex;align-items: center" :style="{'bottom':share == 0 ? '121px':'22px'}">
            <div style="height:30px;width: calc(100% - 62px);">
                <el-slider class="opacitySlider" v-model="opacity" style="width: 100%;height: 30px" :show-tooltip="false" :min="0" :max="1" :step="0.01" @change="changeOpacity"></el-slider>
            </div>
            <div style="height: 30px;width: 30px;border-radius: 4px;background: rgba(42, 130, 228, 1);text-align: center;margin-left: 27px" @click="reSetFun">
                <div style="font-size: 14px;color: white;line-height: 30px;" class="iconfontFamily">&#xe74f;</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getMapboxToken} from "@/utils/utils";
    import mapboxgl from "mapbox-gl";
    import HeatMap from './SmartLayerComponents/HeatMap'
    import HistogramMap from "./SmartLayerComponents/HistogramMap";
    import DataGrid from "./SmartLayerComponents/DataGrid";
    import * as turf from "@turf/turf";
    export default {
        name: "MarkLayer",
        components:{
            HeatMap,
            HistogramMap,
            DataGrid,
        },
        data(){
            return{
                map:'',
                layerTypeSelect:'',//默认：网格图 dataGrid，柱状图 hismap ,热力图 heatmap
                share:0,//分享
                opacity:1,//图层透明度
                dataArray:[],//
                context:'',
                source:'',
                legend:{
                    minValue:0,
                    maxValue:0,
                    colorList:[
                    {
                      "hexColor":"#FF0000",
                      "color":"#FF0000FF",
                      "text":"色块1红色"
                    },
                    {
                      "hexColor":"#1FFF00",
                      "color":"#1FFF00FF",
                      "text":"色块2绿色"
                    },
                    {
                      "hexColor":"#00E2FF",
                      "color":"#00E2FFFF",
                      "text":"色块3蓝色"
                    },
                    {
                      "hexColor":"#5F00FF",
                      "color":"#5F00FFFF",
                      "text":"色块4深蓝"
                    },
                    {
                      "hexColor":"#FF00CF",
                      "color":"#FF00CFFF",
                      "text":"色块5深粉"
                    },
                    {
                      "hexColor":"#FDFF00",
                      "color":"#FDFF00FF",
                      "text":"色块6黄色"
                    }
                  ],
                },
                showSimpleInfo:true,//是否展示缩略信息
                infoCardShow:true,
                configShow:false
            }
        },
        methods:{
            async initMapbox(){
                await getMapboxToken()
                mapboxgl.accessToken = this.globalJS.mapboxToken;
                this.map = new mapboxgl.Map({
                    container: 'layerMap',
                    center:[100, 31],
                    zoom: 3,
                    style: this.globalJS.mapboxStyle,
                    preserveDrawingBuffer:true,
                    localIdeographFontFamily: '',
                    projection: {name:'globe'},
                    transformRequest: (url) => {
                        if (url.indexOf('https://api.mapbox.com') > -1) {
                            return {
                                url: url.replace('https://api.mapbox.com', 'https://earth.jl1.cn/mapbox'),
                            };
                        }
                    }
                });
                this.map.on('load', () => {
                    this.map.addSource('mapbox-dem', {
                        type: 'raster-dem',
                        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
                        tileSize: 512,
                        maxZoom: 14,
                    });
                    this.map.setTerrain({'source': 'mapbox-dem', 'exaggeration': 1});
                    this.map.addLayer({
                        id: 'sky',
                        type: 'sky',
                        paint: {
                            'sky-type': 'atmosphere',
                            'sky-atmosphere-sun': [0.0, 0.0],
                            'sky-atmosphere-sun-intensity': 15,
                        }
                    });

                    this.map.addSource('china1m2021', {
                        type: 'raster',
                        tiles: [
                            this.globalJS.china1mUrl_2021
                        ],
                        tileSize: 256,
                        scheme: 'tms',
                    });
                    this.map.addLayer({
                        id: 'china1mLayer',
                        type: 'raster',
                        source: 'china1m2021',
                    });
                    if(this.$route.query.fileId)
                        this.getLayerInfo()
                });
            },
            //选择图层
            selectLayerType(val){
                this.layerTypeSelect = val
            },
            getLayerInfo(){
              console.log("PPPPPP")
              this.$http.get('/authorApi/z-geo-file/openFileLayer?fileId='+this.$route.query.fileId).then(response => {
                    if(response.body.status === 200){
                        // this.dataArray =response.body.data.geoPoints
                        // this.context = response.body.data.context
                        // this.source = response.body.data.source
                        // this.legend.maxValue = response.body.data.legend.maxValue
                        // this.legend.minValue = response.body.data.legend.minValue
                        // this.layerTypeSelect = response.body.data.layerType
                    }
                })
            },

            mapFitBoundary(geo){
                const bbox = turf.bbox(geo)
                this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]],{
                    linear: false,
                    animate: true,
                    // padding: {top: 120, bottom:130, left: 190, right: 500},
                })
            },
            showLayerCard(val){
                if(val === 1)
                    this.infoCardShow = false
                else
                    this.infoCardShow = true
            },
            showLayerConfig(val){
                if(val === 1)
                    this.configShow = false
                else
                    this.configShow = true
            },
            //改变透明度
            changeOpacity(){
                if(this.layerTypeSelect === 'heatmap'){
                    this.$refs.heatMap.reSetLayer(this.opacity)
                }
                if(this.layerTypeSelect === 'hismap'){
                    this.$refs.hisMap.reSetLayer(this.opacity)
                }
                if(this.layerTypeSelect === 'dataGrid')
                    this.$refs.dataGrid.reSetLayer(this.opacity)
            },
            reSetFun(){
                this.opacity = 1
                this.mapFitBoundary(this.dataArray)
                if(this.layerTypeSelect === 'heatmap'){
                    this.$refs.heatMap.reSetLayer(this.opacity)
                }
                if(this.layerTypeSelect === 'hismap'){
                    this.$refs.hisMap.reSetLayer(this.opacity)
                }
                if(this.layerTypeSelect === 'dataGrid')
                    this.$refs.dataGrid.reSetLayer(this.opacity)
            }
        },
        computed:{
            barBackground(){
                if(this.legend.colorList.length === 0){
                    return
                }else{
                    let colorCount = this.legend.colorList.length
                    let step = 1/(colorCount - 1)
                    let colorStr = 'linear-gradient(90deg, '
                    this.legend.colorList.forEach((item,index)=>{
                        colorStr = colorStr + item.hexColor + ' ' + step*index*100 + '%'
                        if(index !== colorCount - 1){
                            colorStr = colorStr + ','
                        }
                    })
                    colorStr = colorStr + ')'
                    return colorStr
                }
            },
        },
        mounted() {
            window.smartLayerInfo = this
            this.share = this.$route.query.share

            this.initMapbox()
        }
    }
</script>

<style scoped>

    . markLayer {
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    /*//text-align: center;*/
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    /*// 禁止长按选择文字*/
    -webkit-user-select: none;

    /*// 禁止长按呼出菜单*/
    -webkit-touch-callout: none;
    }
    .text-style{
        font-size: 14px;
        color: rgba(74, 74, 74, 1);
    }

    .smartLayerInfo::-webkit-scrollbar {
        width: 0px; /*对垂直滚动条有效*/
        height: 0;  /*对水平滚动条有效*/
    }
    /*定义滚动条的轨道颜色、内阴影及圆角*/
    .smartLayerInfo::-webkit-scrollbar-track {
        background-color: #FFFFFF;
        /*border-radius: 2px;*/
        border-top-right-radius:  4px;
        border-bottom-right-radius: 4px;
        width: 0;
    }
    /*定义滑块颜色、内阴影及圆角*/
    .smartLayerInfo::-webkit-scrollbar-thumb {
        background-color: rgba(42, 130, 228, 1);
        border-radius: 2px;
    }
    .bgImg{
        background: url("../../assets/SmartLayer/bg.png") no-repeat right top;
    }




</style>
<style>
    .opacitySlider .el-slider__runway{
        margin: 13px 0 !important;
    }
    .opacitySlider .el-slider__button{
        border: none !important;
    }
    .opacitySlider .el-slider__bar{
        background: linear-gradient(90deg, rgba(48, 104, 255, 1) 0%, rgba(136, 159, 250, 1) 100%) !important;
    }
</style>
